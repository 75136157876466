import { FC } from 'react'
import { AccessTimeOutlined as ShiftsMuiIcon } from '@mui/icons-material'
import { IconOwnProps, SxProps, Theme } from '@mui/material'

type ShiftsIconProps = IconOwnProps & { sx?: SxProps<Theme> }

const ShiftsIcon: FC<ShiftsIconProps> = ({ ...props }) => {
  return <ShiftsMuiIcon {...props} />
}

export default ShiftsIcon
