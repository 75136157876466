/**
 * This module is designed to be loaded as the very first import in the app.
 * This is important to make sure that the logging is setup and initialised
 * before any other modules are imported and start trying to log things. If we
 * don't do this first those log calls end up being lost because the logger may
 * not have been enabled at the point the call is made.
 */

import { isDebugEnabled, logger } from '.'

if (isDebugEnabled()) {
  logger.enable()
  logger.info('Loaded app logging enabled')
}
