import { FC } from 'react'
import { HomeRounded as HomeMuiIcon } from '@mui/icons-material'
import { IconOwnProps, SxProps, Theme } from '@mui/material'

type HomeIconProps = IconOwnProps & { sx?: SxProps<Theme> }

const HomeIcon: FC<HomeIconProps> = ({ ...props }) => {
  return <HomeMuiIcon {...props} />
}

export default HomeIcon
