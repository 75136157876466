import { forwardRef, ReactElement, Ref } from 'react'
import { Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'

// Create a separate transition component for each direction
const TransitionUp = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const TransitionDown = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement },
  ref: Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />
})

const TransitionLeft = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement },
  ref: Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />
})

const TransitionRight = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement },
  ref: Ref<unknown>,
) {
  return <Slide direction="right" ref={ref} {...props} />
})

// Map direction strings to transition components
export const transitionComponents = {
  up: TransitionUp,
  down: TransitionDown,
  left: TransitionLeft,
  right: TransitionRight,
}
