import { FC, useEffect, useMemo } from 'react'
import { Action, ErrorBoundary, singletonQueryClient } from './utils'
import dayjs from 'dayjs'
import './index.css'
import { QueryClientProvider } from '@tanstack/react-query'
import { SnackbarProvider, SnackbarProviderProps } from 'notistack'
import { useCompanySettings } from './hooks'
import useAppStore from './state/store'
import getTheme from './theme'
import { RouterProvider } from 'react-router-dom'
import getRouter from './router'
import { ThemeProvider } from '@mui/material'
import { LoadedSnackbar } from './components'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { DialogProvider } from './hooks/useDialog/DialogProvider'
import { useShallow } from 'zustand/react/shallow'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const snackbarProviderProps: SnackbarProviderProps = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  maxSnack: 3,
  autoHideDuration: 6000,
  action: Action,
  preventDuplicate: true,
  hideIconVariant: true,
  Components: {
    loadedSnackbar: LoadedSnackbar,
  },
}

const App: FC = () => {
  const [activeCompany, activeStaffMember] = useAppStore(
    useShallow((state) => [state.activeCompany, state.activeStaffMember]),
  )
  const colorMode = useAppStore((state) => state.colorMode)

  const theme = useMemo(() => getTheme(colorMode), [colorMode])
  const routes = useMemo(
    () => getRouter(activeCompany, activeStaffMember),
    [activeCompany, activeStaffMember],
  )

  const { localePreference, weekStartPreference } = useCompanySettings()

  useEffect(() => {
    dayjs.updateLocale(localePreference.toLocaleLowerCase(), {
      weekStart: weekStartPreference,
    })
  }, [localePreference, weekStartPreference])

  return (
    <QueryClientProvider client={singletonQueryClient}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider {...snackbarProviderProps}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={localePreference.toLocaleLowerCase()}
          >
            <ErrorBoundary>
              <DialogProvider>
                <RouterProvider
                  router={routes}
                  future={{
                    v7_startTransition: true,
                  }}
                />
                <ReactQueryDevtools initialIsOpen={false} />
              </DialogProvider>
            </ErrorBoundary>
          </LocalizationProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App
