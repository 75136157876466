import { DialogOptions } from './dialogOptions'
import { useDialogContext } from './DialogProvider'

export const useDialog = () => {
  const { openDialog, closeDialog, isOpen } = useDialogContext()

  const showDialog = (options: DialogOptions) => openDialog(options)

  return {
    openDialog: showDialog,
    closeDialog,
    isOpen,
  }
}
