export {
  LoadedUnavailabilityStatus,
  LoadedUnavailabilityRecordType,
  DaysOfTheWeek,
} from '@loadedhub/core-api-sdk'

export enum Country {
  NewZealand = 'New Zealand',
  Australia = 'Australia',
}

export enum CompanyPreference {
  DayStart = 'date.daycutoff.daystart',
  WeekStart = 'date.daycutoff.weekstart',
  Locale = 'locale.locale',
  Province = 'locale.province',
  TimeZone = 'locale.timeZone',
  TimePermissionAllowsWageAndSalarySummary = 'permissions.timeAllowsSummary',
  DefaultFreightTaxRate = 'tax.defaultFreightRate',
  OverrideSalesTaxRate = 'tax.overrideSalesTax',
  ClockinRounding = 'time.clockinRounding',
  IncludePosClockins = 'time.includePosClockins',
  PayrollSystem = 'time.payrollSystem',
}

export enum PermissionType {
  Manager = 'Manager',
  Report = 'Report',
  Cashup = 'Cashup',
  Stock = 'Stock',
  StockShare = 'StockShare',
  Payroll = 'Payroll',
  Salary = 'Salary',
  Time = 'Time',
}

export enum PayrollSystem {
  Custom = 'Custom',
  AcePayroll = 'AcePayroll',
  AffinityPayroll = 'AffinityPayroll',
  CrystalPayroll = 'CrystalPayroll',
  DataPay = 'DataPay',
  Ezypay = 'Ezypay',
  Flexitime = 'Flexitime',
  IPayroll = 'IPayroll',
  MyobExo = 'MyobExo',
  MyobPayroll = 'MyobPayroll',
  PayHero = 'PayHero',
  Paysauce = 'Paysauce',
  Smartly = 'Smartly',
  XeroAu = 'XeroAu',
  XeroNz = 'XeroNz',
}

export enum Locale {
  NZ = 'en-NZ',
  AU = 'en-AU',
  UK = 'en-GB',
  IE = 'en-IE',
}

export enum ShiftType {
  Clockin = 'Clockin',
  Roster = 'Roster',
}
