import { coreApi } from '@/api'
import { singletonQueryClient } from '@/utils'
import {
  LoadedUnavailabilityRecordType,
  LoadedUnavailabilityStatus,
} from '@/constants'
import {
  IUnavailabilityRecord,
  UnavailabilityRecord,
  UnavailabilityRecordUpdateRequest,
} from '@/models'

/**
 * Get details of a staff members unavailability
 * @param staffMemberId - staff member to get the unavailability records for
 * @param unavailabilityStatus - optional filter for the unavailability status
 * @returns Promise<IUnavailabilityRecord[]>
 */
export const getByStaffMemberId = async (
  staffMemberId: string,
  unavailabilityStatus?: LoadedUnavailabilityRecordType,
): Promise<IUnavailabilityRecord[]> => {
  return singletonQueryClient.fetchQuery({
    queryKey: ['unavailability', staffMemberId, unavailabilityStatus],
    queryFn: async () => {
      return await coreApi.getUnavailability(
        staffMemberId,
        unavailabilityStatus,
      )
    },
  })
}

/**
 * Create an unavailability record
 * @param unavailability to create
 * @returns Promise<Unavailability>
 */
export const add = async (
  unavailability: IUnavailabilityRecord,
): Promise<IUnavailabilityRecord> => {
  const result = await coreApi.addUnavailability(
    unavailability as UnavailabilityRecord,
  )

  return result
}

export const cancel = async (id: string): Promise<IUnavailabilityRecord> => {
  return await coreApi.updateUnavailabilityStatus(
    id,
    new UnavailabilityRecordUpdateRequest({
      status: LoadedUnavailabilityStatus.Cancelled,
    }),
  )
}

export const update = async (
  _unavailability: IUnavailabilityRecord,
): Promise<IUnavailabilityRecord> => {
  throw new Error('Method not implemented in the backend.')
}

/**
 * Update an unavailability record status
 * @param id Id of the unavailability record
 * @param status Status to update
 * @returns Promise<IUnavailabilityRecord>
 */
export const updateStatus = async (
  id: string,
  status: LoadedUnavailabilityStatus,
): Promise<IUnavailabilityRecord> => {
  const payload = {
    status: status,
  } as UnavailabilityRecordUpdateRequest
  return await coreApi.updateUnavailabilityStatus(id, payload)
}

/**
 * Send an existing unavailability request by id
 * @param id Id of the request to send
 * @returns Promise<IUnavailabilityRecord>
 */
export const sendRequest = async (
  id: string,
): Promise<IUnavailabilityRecord> => {
  const result = await coreApi.sendUnavailabilityApprovalRequest(id)
  return result
}
