import { Navigate, RouteObject } from 'react-router-dom'
import { default as Auth } from './routes/auth'
import { default as App } from './routes/app'
import AppLayout from './routes/app/AppLayout'
import withSuspense from './common/app/WithSuspense'

export const Routes = [
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        path: 'home',
        element: withSuspense(App.Home.Component),
        loader: App.Home.loader,
      },

      {
        path: 'shifts',
        element: withSuspense(App.Shifts.Component), // Uses Outlet for nested routes
        children: [
          { path: '', element: <Navigate to="my-shifts" replace /> },
          {
            path: 'my-shifts',
            element: withSuspense(App.Shifts.MyShifts.Component),
            loader: App.Shifts.MyShifts.loader,
          },
          {
            path: 'roster',
            element: withSuspense(App.Shifts.Roster.Component),
            loader: App.Shifts.Roster.loader,
          },
          {
            path: 'grab-a-shift',
            element: withSuspense(App.Shifts.GrabAShift.Component),
            loader: App.Shifts.GrabAShift.loader,
          },
        ],
      },
      {
        path: 'messages',
        element: withSuspense(App.Messages.Component),
        loader: App.Messages.loader,
      },
      {
        path: 'leave',
        element: withSuspense(App.Leave.Component), // Uses Outlet for nested routes
        children: [
          //TODO: roll back to 'my-leave' once the my-leave page is ready
          { path: '', element: <Navigate to="my-unavailability" replace /> },
          {
            path: 'my-leave',
            element: withSuspense(App.Leave.MyLeave.Component),
            loader: App.Leave.MyLeave.loader,
          },
          {
            path: 'my-unavailability',
            element: withSuspense(App.Leave.MyUnavailability.Component),
            loader: App.Leave.MyUnavailability.loader,
          },
        ],
      },
      {
        path: 'team',
        element: withSuspense(App.Team.Component),
        loader: App.Team.loader,
      },
      {
        path: '*',
        // TODO: Put this back when we have a home page
        // element: <Navigate to="/home" />,
        element: <Navigate to="/leave/my-unavailability" />,
      },
      {
        path: '/',
        // TODO: Put this back when we have a home page
        // element: <Navigate to="/home" />,
        element: <Navigate to="/leave/my-unavailability" />,
      },
    ],
  },
] as RouteObject[]

export const AuthRoutes = [
  {
    path: 'connect',
    element: <Auth.Connect.Component />,
  },
  {
    path: '*',
    element: <Navigate to="/connect" />,
  },
] as RouteObject[]

export const CommonRoutes = [
  {
    path: 'install',
    element: <Auth.Install.Component />,
  },
]
