import { coreApi } from '@/api'
import { errorHandler } from '@/api/helper'
import appConfig from '@/appConfig'
import {
  AuthorizationData,
  transformUserSessionOnGet,
  IUserSession,
} from '@/models'
import { AuthError, logger, setAuthorizationData } from '@/utils'
import dayjs from 'dayjs'

//TODO: We need to create a new client id for the employee app, leave timeclock for now
const EMPLOYEE_CLIENT_ID = 'mercury'

export const login = async (
  username: string,
  password: string,
  companyId?: string,
  companyName?: string,
): Promise<boolean> => {
  try {
    const body = new URLSearchParams({
      grant_type: 'password',
      client_id: EMPLOYEE_CLIENT_ID,
      username,
      password,
    })

    // generate a new device id that will be used while this device is connected
    const deviceId = btoa(`${companyName} (${dayjs().toISOString()})`)

    const response = await fetch(`${appConfig.mercuryApiUrl}/token`, {
      method: 'POST',
      headers: {
        ...(companyId && {
          'x-loaded-company-id': companyId,
          'x-loaded-device-id': deviceId,
        }),
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: body,
    })

    if (!response.ok) {
      logger.error(`received HTTP ${response.status} sending data to '/token'`)

      if (response.status === 401) {
        throw new AuthError('API client is not authenticated')
      }

      return errorHandler(response)
    }

    const contentType = response.headers.get('Content-Type')
    if (!contentType?.includes('application/json')) {
      throw new AuthError('API client is not authenticated')
    }

    const data = await response.json()

    // Persist auth data for reuse when tokens expire
    setAuthorizationData(
      deviceId,
      data.access_token,
      data.refresh_token,
      dayjs().add(data.expires_in, 'seconds'),
    )

    return true
  } catch (error) {
    logger.error('Error logging in:', error)
    throw new AuthError('Invalid username or password')
  }
}

export const logout = async (refreshToken: string): Promise<void> => {
  try {
    const body = new URLSearchParams({
      refresh_token: refreshToken,
    })

    const response = await fetch(`${appConfig.mercuryApiUrl}/refresh-token`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: body,
    })

    if (!response.ok) {
      logger.error(
        `received HTTP ${response.status} sending data to '/refresh-token'`,
      )
      return errorHandler(response)
    }
  } catch (error) {
    logger.error('Error logging out:', error)
    throw new AuthError('Unable to log out')
  }
}

export const refreshAccessToken = async (
  refreshToken: string,
): Promise<Omit<AuthorizationData, 'deviceId'>> => {
  try {
    const body = new URLSearchParams({
      grant_type: 'refresh_token',
      client_id: EMPLOYEE_CLIENT_ID,
      refresh_token: refreshToken,
    })

    const response = await fetch(`${appConfig.mercuryApiUrl}/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: body,
    })

    if (!response.ok) {
      logger.error(
        `received HTTP ${response.status} sending data to refresh token`,
      )

      if (response.status === 401) {
        throw new AuthError('API client is not authenticated')
      }

      return errorHandler(response)
    }

    const contentType = response.headers.get('Content-Type')
    if (!contentType?.includes('application/json')) {
      throw new AuthError('API client is not authenticated')
    }

    const data = await response.json()

    return {
      accessToken: data.access_token,
      refreshToken: data.refresh_token,
      expiryDate: dayjs().add(data.expires_in, 'seconds'),
    }
  } catch (error) {
    logger.error('Error refreshing token:', error)
    throw new AuthError('Unable to refresh token')
  }
}

export const getUserSession = async (): Promise<IUserSession> => {
  const userSession = await coreApi.getUserSession()

  const transformedUserSession = transformUserSessionOnGet(userSession)

  return transformedUserSession
}
