import {
  createLeaveSlice,
  LeaveSlice,
} from '@/components/routes/app/leave/leaveSlice'
import { createSharedSlice, SharedSlice } from '@/components/sharedSlice'
import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { AppSlice, createAppSlice } from './appSlice'
import { devtools } from 'zustand/middleware'
import { isDebugEnabled, logger } from '@/utils'

type AppStore = AppSlice & SharedSlice & LeaveSlice

/**
 * Overall app state store
 */
const useAppStore = create(
  devtools(
    persist<AppStore>(
      (...props) => ({
        ...createAppSlice(...props),
        ...createSharedSlice(...props),
        ...createLeaveSlice(...props),
      }),
      {
        name: '@@loaded/employee/app-store',
        storage: createJSONStorage(() => localStorage),
        partialize: (state) => {
          // This allows us to partialize the state when persisting it
          // to localStorage. This is useful when we want to persist only
          // a subset of the state
          return {
            colorMode: state.colorMode,
            activeCompany: state.activeCompany,
            activeStaffMember: state.activeStaffMember,
            userDetails: state.userDetails
              ? {
                  ...state.userDetails,
                  companies: undefined, // Omit companies array
                }
              : undefined,
          } as never
        },
        onRehydrateStorage: (state) => {
          // Zustand rehydration happens during modules import which makes it hard
          // to enable logging before the hydration starts. This is a workaround
          // to make sure that logging is enabled before the hydration starts.
          // This is also imported in the index.tsx file so that it is executed
          // before anything else - just leaving it there as a safety measure in case
          // the store rehydration is skipped/removed/turned off
          if (isDebugEnabled()) {
            logger.enable()
          }

          logger.debug('Store hydration starts', state)

          return (state, error) => {
            if (error) {
              logger.error('Fail to rehydrate store', error)
            } else {
              logger.debug('Store hydration finished', state)
            }
          }
        },
      },
    ),
    {
      enabled: process.env.NODE_ENV === 'development' && isDebugEnabled(),
      name: 'App Store',
    },
  ),
)

export default useAppStore
