import { useState, useEffect, FC, ReactNode } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  SlideProps,
  DialogProps,
} from '@mui/material'
import { transitionComponents } from './Transition'
import { FullPageLoader } from '@/components'
import { BOTTOM_NAV_HEIGHT } from '@/constants'

type DialogComponentProps = {
  isOpen: boolean
  closeDialog: () => void
  title?: ReactNode
  renderContent?: () => ReactNode
  renderFooter?: () => ReactNode
  onOpen?: () => Promise<void> // Async function to call on open
  transitionDirection?: SlideProps['direction']
  dialogProps?: Omit<DialogProps, 'open'> // Omit open prop from DialogProps as it is handled by DialogComponent
}

const DialogComponent: FC<DialogComponentProps> = ({
  isOpen,
  closeDialog,
  title,
  renderContent,
  renderFooter,
  onOpen,
  transitionDirection,
  dialogProps,
}) => {
  const [loading, setLoading] = useState(false)

  const TransitionComponent = transitionComponents[transitionDirection ?? 'up']

  useEffect(() => {
    const loadData = async () => {
      if (isOpen && onOpen) {
        setLoading(true)
        try {
          await onOpen()
        } finally {
          setLoading(false)
        }
      }
    }

    loadData()
  }, [isOpen, onOpen])

  // This sx props take into account the bottom nav height
  const bottomDialogSxProps = {
    '& .MuiDialog-paper': {
      width: '100%',
      height: '40%',
      margin: 0,
      maxWidth: 'none',
      borderRadius: 0,
      position: 'fixed',
      bottom: BOTTOM_NAV_HEIGHT,
    },
  }

  // This sx props take into account the bottom nav height
  const sideDialogSxProps = {
    marginBottom: BOTTOM_NAV_HEIGHT,
  }

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      fullWidth
      TransitionComponent={TransitionComponent}
      {...dialogProps}
      sx={{
        ...((transitionDirection === 'left' ||
          transitionDirection === 'right') &&
          sideDialogSxProps),

        ...(transitionDirection === 'up' && bottomDialogSxProps),

        ...dialogProps?.sx,
      }}
    >
      {title && typeof title === 'string' ? (
        <DialogTitle>{title}</DialogTitle>
      ) : (
        title
      )}
      <DialogContent id="dialog-content" className={'hide-scrollbar'}>
        {loading ? <FullPageLoader /> : renderContent?.()}
      </DialogContent>
      {renderFooter && <DialogActions>{renderFooter()}</DialogActions>}
    </Dialog>
  )
}

export default DialogComponent
