import { FC } from 'react'
import { ChatOutlined as MessagesMuiIcon } from '@mui/icons-material'
import { Badge, BadgeProps, IconOwnProps, SxProps, Theme } from '@mui/material'

type MessagesIconProps = IconOwnProps & {
  sx?: SxProps<Theme>
  badgeProps?: BadgeProps
}

const MessagesIcon: FC<MessagesIconProps> = ({ ...props }) => {
  return (
    <Badge color="primary" {...props.badgeProps}>
      <MessagesMuiIcon {...props} />
    </Badge>
  )
}

export default MessagesIcon
