import { logger } from '@/utils'
import { defer } from 'react-router-dom'

export default async function loader() {
  logger.debug('[loader] Home loader')

  return defer({
    data: Promise.all([])
      .then(() => true)
      .catch((error) => {
        logger.error('Home Promise.all error', error)
        return false
      }),
  })
}
