import { createBrowserRouter } from 'react-router-dom'
import { Routes, AuthRoutes, CommonRoutes } from './components/Routes'
import {
  IStaffMembersDetailSummaryResponse,
  IUserCompanyInfoWithPermissions,
} from './models'

export default (
  activeCompany: IUserCompanyInfoWithPermissions | undefined,
  activeStaffMember: IStaffMembersDetailSummaryResponse | undefined,
) =>
  createBrowserRouter(
    [
      ...CommonRoutes,
      ...(activeCompany && activeStaffMember ? Routes : AuthRoutes),
    ],
    {
      future: {
        //https://reactrouter.com/6.28.0/upgrading/future
        v7_partialHydration: true,
        v7_relativeSplatPath: true,
        v7_normalizeFormMethod: true,
        v7_skipActionErrorRevalidation: true,
      },
    },
  )
