import { FC } from 'react'
import { GroupsOutlined as TeamMuiIcon } from '@mui/icons-material'
import { IconOwnProps, SxProps, Theme } from '@mui/material'

type TeamIconProps = IconOwnProps & { sx?: SxProps<Theme> }

const TeamIcon: FC<TeamIconProps> = ({ ...props }) => {
  return <TeamMuiIcon {...props} />
}

export default TeamIcon
