import { SharedSlice } from '@/components/sharedSlice'
import {
  IStaffMembersDetailSummaryResponse,
  IUserCompanyInfoWithPermissions,
  UserDetails,
} from '@/models'
import { authService } from '@/services'
import {
  exceptionMessageHandler,
  getAuthorizationData,
  logger,
  removeActiveCompanyId,
  removeActiveStaffMemberId,
  removeAuthorizationData,
  setActiveCompanyId,
  setActiveStaffMemberId,
} from '@/utils'
import { PaletteMode } from '@mui/material'
import { StateCreator } from 'zustand'

// Leave slice types
interface AppState {
  colorMode: PaletteMode
  activeCompany: IUserCompanyInfoWithPermissions | undefined
  activeStaffMember: IStaffMembersDetailSummaryResponse | undefined
  userDetails: UserDetails | undefined
}

export interface AppSlice extends AppState {
  resetAppSlice: () => void

  setActiveCompany: (
    activeCompany: IUserCompanyInfoWithPermissions | undefined,
  ) => void

  setActiveStaffMember: (
    activeStaffMember: IStaffMembersDetailSummaryResponse | undefined,
  ) => void

  toggleColorMode: () => void

  login: (username: string, password: string) => Promise<boolean>

  logout: () => Promise<void>
}

const initialState: AppState = {
  colorMode:
    (localStorage.getItem('@@loaded/employee/theme/mode') as PaletteMode) ||
    'light',
  activeCompany: undefined,
  activeStaffMember: undefined,
  userDetails: undefined,
}

export const createAppSlice: StateCreator<
  AppSlice & SharedSlice,
  [['zustand/devtools', never]],
  [],
  AppSlice
> = (set, get) => ({
  ...initialState,

  setActiveCompany: (
    activeCompany: IUserCompanyInfoWithPermissions | undefined,
  ) => {
    if (!activeCompany?.id) {
      removeActiveCompanyId()
      set({ activeCompany: undefined })
      return
    }

    setActiveCompanyId(activeCompany.id)
    set({ activeCompany })
  },

  setActiveStaffMember: (
    activeStaffMember: IStaffMembersDetailSummaryResponse | undefined,
  ) => {
    if (!activeStaffMember?.id) {
      removeActiveStaffMemberId()
      set({ activeStaffMember: undefined })
      return
    }

    setActiveStaffMemberId(activeStaffMember.id)
    set({ activeStaffMember })
  },

  toggleColorMode: () => {
    const nextColorMode = get().colorMode === 'light' ? 'dark' : 'light'
    localStorage.setItem('@@loaded/employee/theme/mode', nextColorMode)
    set(() => ({
      colorMode: nextColorMode,
    }))
  },

  login: async (username: string, password: string) => {
    try {
      await authService.login(username, password)

      const userSession = await authService.getUserSession()
      const userDetails: UserDetails = {
        hasPassword: userSession.hasPassword,
        firstName: userSession.firstName,
        lastName: userSession.lastName,
        email: userSession.userName,
        companies: userSession.companiesWithMethods || [],
      }

      set(() => ({
        userDetails: userDetails,
      }))
      return true
    } catch (error) {
      exceptionMessageHandler(error, 'Error when trying to log in')
      logger.error('Error logging in:', error)
      return false
    }
  },

  logout: async () => {
    try {
      const authorizationData = getAuthorizationData()
      await authService.logout(authorizationData.refreshToken)
    } catch (error) {
      exceptionMessageHandler(error, 'Unable to disconnect device')
      logger.error('Error logging out:', error)
      return
    }

    removeActiveCompanyId()
    removeAuthorizationData()

    // The auth-store used for persisting the auth data needs to be cleared
    // but we can't do it from within the store itself because it doesn't fully
    // clears all the data so it is done inmmediately after the logout
    // function is called -> useAppStore.persist.clearStorage()

    set(() => ({
      activeCompany: undefined,
      userDetails: undefined,
    }))
  },

  resetAppSlice: () => set(initialState),
})
