import { DaysOfTheWeek } from './enums'

export const BOTTOM_NAV_HEIGHT = '74px'
export const TABS_HEIGHT = '48px'

export const daysOfWeek: DaysOfTheWeek[] = [
  DaysOfTheWeek.Monday,
  DaysOfTheWeek.Tuesday,
  DaysOfTheWeek.Wednesday,
  DaysOfTheWeek.Thursday,
  DaysOfTheWeek.Friday,
  DaysOfTheWeek.Saturday,
  DaysOfTheWeek.Sunday,
]
