import useAppStore from '@/state/store'
import { logger } from '@/utils'
import { defer } from 'react-router-dom'

export default async function loader() {
  logger.debug('[loader] My Unavailability loader')

  const staffMemberId = useAppStore.getState().activeStaffMember?.id

  const staffMemberUnavailabilityRecords =
    staffMemberId &&
    useAppStore.getState().getUnavailabilitiesByStaffMemberId(staffMemberId)

  return defer({
    data: Promise.all([staffMemberUnavailabilityRecords])
      .then(() => true)
      .catch((error) => {
        logger.error('My Unavailability Promise.all error', error)
        return false
      }),
  })
}
