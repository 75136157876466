import { FC } from 'react'
import { BeachAccessRounded as LeaveMuiIcon } from '@mui/icons-material'
import { IconOwnProps, SxProps, Theme } from '@mui/material'

type LeaveIconProps = IconOwnProps & { sx?: SxProps<Theme> }

const LeaveIcon: FC<LeaveIconProps> = ({ ...props }) => {
  return <LeaveMuiIcon {...props} />
}

export default LeaveIcon
